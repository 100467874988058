import {
  Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  Text,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Icon,
  Center,
  Divider,
  Button,
} from '@chakra-ui/react';
import { useSession, signOut } from 'next-auth/react';
import router from 'next/router';
import { FiChevronDown } from 'react-icons/fi';
import { MdLogout } from 'react-icons/md';
import { TbLogout } from 'react-icons/tb';
import { IoTicketOutline } from 'react-icons/io5';

export default function UserProfile() {
  const { data: session } = useSession();
  return (
    <HStack zIndex={1}>
      {/* <IconButton
    size="lg"
    variant="ghost"
    aria-label="open menu"
    icon={<FiBell />}
  /> */}
      {!session && (
        <Button
          variant="lpLoginButton"
          leftIcon={<TbLogout color="black" />}
          colorScheme="orange"
          onClick={() => {
            router.push('/login');
          }}
        >
          Entrar
        </Button>
      )}
      {session && (
        <Flex alignItems="center">
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}
            >
              <HStack spacing="4">
                <Avatar
                  size="md"
                  fontFamily={'mainfont'}
                  color="#1D1D1D"
                  name={
                    session.user.name ? session.user.name : session.user.email
                  }
                />
                <VStack
                  display={{ base: 'none', xl: 'flex' }}
                  align="flex-start"
                  spacing="1px"
                  ml="2"
                  fontFamily={'mainfont'}
                  color="#1D1D1D"
                  opacity={'60%'}
                >
                  <Text fontFamily={'mainfont'} color="#1D1D1D" opacity={'80%'}>
                    {session.user.name ? session.user.name : session.user.email}
                  </Text>
                  {/* {session?.user?.phone ?? (
                    <Text color="white" as="i" onClick={() => router.push("/phone")} cursor="pointer" fontSize='sm' >
                      nenhum telefone cadastrado
                    </Text>
                  )} */}
                </VStack>
              </HStack>
            </MenuButton>
            <MenuList color="#1D1D1D" bg="white">
              <MenuItem
                onClick={() => {
                  router.push('/account/luckynumbers');
                }}
                icon={<IoTicketOutline />}
              >
                {' '}
                <Text fontFamily={'mainfont'} color="#1D1D1D" size={'md'}>
                  {' '}
                  Meus Números da Sorte{' '}
                </Text>
              </MenuItem>
              <MenuDivider display={{ base: 'flex', xl: 'none' }} />
              <MenuItem
                display={{ base: 'flex', xl: 'none' }}
                color="#1D1D1D"
                opacity={'60%'}
                icon={<MdLogout />}
                onClick={() => {
                  signOut();
                }}
              >
                Sair
              </MenuItem>
            </MenuList>
          </Menu>
          <Box>
            <FiChevronDown color="black" opacity={'60%'} />
          </Box>
          <Center height="30px" px={2} display={{ base: 'none', xl: 'flex' }}>
            <Divider orientation="vertical" w="1px" h="36px" bgColor="black" />
          </Center>
          <Box w={'5px'} h={'40px'}></Box>
          <Box display={{ base: 'none', xl: 'flex' }}>
            <Icon
              aria-label="sair"
              cursor="pointer"
              color="black"
              opacity={'60%'}
              as={TbLogout}
              w={8}
              h={8}
              onClick={() => {
                signOut();
              }}
            />
          </Box>
        </Flex>
      )}
    </HStack>
  );
}
