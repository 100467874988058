import { Flex, Button, Box, HStack, Link, Icon } from '@chakra-ui/react';
import { MdMenu } from 'react-icons/md';
import UserProfile from '@components/UserProfile';
import { useRouter } from 'next/router';
import { motion } from 'framer-motion';
import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';


export default function Header({
  onOpen,
  ...rest
}: {
  onOpen: any;
  rest?: any;
}) {
  const { data: _session, status } = useSession();
  const [isOnTop, setIsOnTop] = useState(false);
  const router = useRouter();

  const handleScroll = () => {
    const scrollPosition = window.scrollY; // => scroll position
    setIsOnTop(scrollPosition == 0);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Flex
      position="sticky"
      top="0"
      px={[5, 10, 15, 20]}
      height={status === 'unauthenticated' ? '80px' : '90px'}
      zIndex="3"
      alignItems="center"
      bg={isOnTop ? "#FFFFFF" : 'lpHeaderScrollColor'}
      backdropFilter={isOnTop ? undefined : 'saturate(180%) blur(5px)'}
      opacity={0.95}
      justifyContent="space-between"
      {...rest}
    >
      <Icon
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        color="black"
        w={6}
        h={6}
        size="lg"
        aria-label="open menu"
        as={MdMenu}
        zIndex={1}
      />

      <HStack zIndex={1} spacing={6}>
          <Box
            display={{ base: 'none', md: 'flex' }}
            as={motion.div}
            dragConstraints={{ left: -100, right: 100 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition="0.05s linear"
            pr={5}
          >
            <a href='https://clube.lojasrede.com.br/' target='_blank'>
            <Button
              variant="lpHeaderButton"
              size="lg"
              
              // onClick={() => {
              //   router.push('https://clube.lojasrede.com.br/');
              // }}
            >
              Cadastrar no Clube Lojas REDE
            </Button>
              </a>
          </Box>
        <Link
          onClick={() => {
            router.push('/regulations');
          }}
          variant="lpHeaderLinks"
        >
          Regulamentos
        </Link>

        {/* <Link
          onClick={() => {
            router.push('/faq');
          }}
          variant="lpHeaderLinks"
        >
          Dúvidas?
        </Link> */}
      </HStack>
      <UserProfile />
    </Flex>
  );
}
