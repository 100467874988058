import { User } from '@interfaces/User';
import logger from '@logger/logger';
import { useSession } from 'next-auth/react';
// import { useRouter } from 'next/router';
import React, { createContext, useEffect } from 'react';
import { Cookies } from 'react-cookie-consent';

interface LoginInfosContextInterface {}

interface LoginInfosInterfaceProps {
  children: React.Component | any;
}

export const LoginInfosContext = createContext<LoginInfosContextInterface>(
  {} as LoginInfosContextInterface
);

export default function LoginInfosProvider({
  children,
}: LoginInfosInterfaceProps) {
  const { data, status } = useSession();

  // const router = useRouter();

  const handlerMinutesGetLoginInfos = () => {
    logger.debug(data);
    if (data != null && data.user != null) {
      const { userInfos } = data.user as User;
      const updatedInfos =
        Cookies.get('updatedInfos') != undefined
          ? JSON.parse(Cookies.get('updatedInfos'))
          : { hasUpdatedInfos: false };
      // console.log(Cookies.get('updatedInfos'));
      // console.log("updatedInfos",updatedInfos);
      let isAfterDateToWait = false;
      const needWaitDate = updatedInfos[`${data.user.userId}.needWaitDate`];
      if (needWaitDate != null && needWaitDate != undefined) {
        const needWaitDateValue = new Date(needWaitDate);
        const now = new Date();

        // console.log("needWaitDateValue",needWaitDateValue.getTime());
        // console.log("now",now.getTime());
        isAfterDateToWait = needWaitDateValue.getTime() >= now.getTime();
      }
      // console.log("isAfterDateToWait",isAfterDateToWait);
      if (
        updatedInfos.hasUpdatedInfos != true &&
        !isAfterDateToWait &&
        (userInfos == null ||
          userInfos.hasUpdatedInfos == null ||
          userInfos.hasUpdatedInfos == false)
      ) {
        // router.push('/auth/validate_infos');
      }
    }
  };

  useEffect(() => {
    if (status === 'authenticated') {
      handlerMinutesGetLoginInfos();
      setInterval(() => {
        handlerMinutesGetLoginInfos();
      }, 30 * 60000);
    }
  }, [status]);

  return (
    <LoginInfosContext.Provider value={{}}>
      {children}
    </LoginInfosContext.Provider>
  );
}
