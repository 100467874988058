import { Box } from '@chakra-ui/react';
import BannerHeader from './components/banner_header';
import BannerCars from './components/banner_cars';
import BannerHowParticipate from './components/banner_how_participate';
import BannerWinnerPrizes from './components/banner_winner_prizes';
import WinnerPrizesModal from '@components/WinnerPrizesModal';

export default LojasRedeModule;

function LojasRedeModule() {
  return (
    <Box
       backgroundImage={[
        "url('imgs/lojas_rede/background-mobile.png')",
        "url('imgs/lojas_rede/background.png')",
      ]}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
    >
      <BannerHeader/>
      <BannerCars/>
      <BannerHowParticipate />
      <WinnerPrizesModal ChildComponet={BannerWinnerPrizes} />
      {/* <BannerWinnerPrizes /> */}
    </Box>
  );
}
