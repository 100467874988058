import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react';
import { useSession } from 'next-auth/react';
import router from 'next/router';
import CustomNextImage from '@components/ChakraNextImage/newNextImage';
export default BannerHeader;

function BannerHeader() {
  const [isLargerThan480] = useMediaQuery('(min-width: 650px)');
  const { status } = useSession();

  const ImageLogo = () => {
    return (
      <CustomNextImage
        src={'/imgs/lojas_rede/logo-rede.png'}
        alt="Logo Lojas Rede"
        width={'600px'}
        height={'250px'}
      />
    );
  };

  const ImageOba = () => {
    return (
      <CustomNextImage
        src={'/imgs/lojas_rede/oba.png'}
        alt="oba"
        // layout="intrinsic"
        width={'640px'}
        height={'440px'}
      />
    );
  };

  const ImageTextHeader = () => {
    return (
      <Box
        pt={5}
        pr={{
          base: '0',
          sm: '0', // 480px
          md: '0', // 768px
          lg: '32', // 992px
          xl: '32', // 1280px
          '2xl': '32', // 1536px
        }}
        alignSelf={{
          base: 'start',
          sm: 'start', // 480px
          md: 'center', // 768px
          lg: 'center', // 992px
          xl: 'center', // 1280px
          '2xl': 'center', // 1536px
        }}
        w={'-moz-fit-content'}
      >
        <CustomNextImage
          src="/imgs/lojas_rede/text-header.png"
          alt="texto Lojas Rede"
          width={'300px'}
          height={'200px'}
        />
      </Box>
    );
  };

  const TextAndButton = () => {
    return (
      <VStack spacing={6}>
        <Text
          fontFamily="Bigola-Display-Italic"
          px={14}
          align={'center'}
          color="white"
          fontSize={{
            base: '25px',
            sm: '18px', // 480px
            md: '25px', // 768px
            lg: '30px', // 992px
            xl: '40px', // 1280px
            '2xl': '45px', // 1536px
          }}
        >
          Confira seus números da sorte!
        </Text>
        <Button
          borderWidth="0px"
          px={8}
          bg="white"
          _hover={{ bg: 'gray' }}
          _active={{ bg: 'gray' }}
          borderRadius={30}
          variant="outline"
          onClick={() => {
            if (status == 'authenticated') {
              router.push('/account/luckynumbers');
            } else {
              router.push('/login');
            }
          }}
          loadingText="Carregando"
          spinnerPlacement="start"
          size="md"
        >
          <Text
            fontFamily="Montserrat"
            fontSize={{
              base: '20px',
              sm: '15px', // 480px
              md: '20px', // 768px
              lg: '20px', // 992px
              xl: '25px', // 1280px
              '2xl': '25px', // 1536px
            }}
          >
            {status == 'authenticated' ? 'Meus Números' : 'Entrar'}
          </Text>
        </Button>
      </VStack>
    );
  };

  if (isLargerThan480) {
    return (
      <HStack
        justify={{
          base: 'space-between',
          sm: 'space-between', // 480px
          md: 'space-between', // 768px
          lg: 'space-between', // 992px
          xl: 'space-between', // 1280px
          '2xl': 'space-evenly', // 1536px
        }}
        p={2}
        pl={{
          base: 2,
          sm: 2, // 480px
          md: 2, // 768px
          lg: 8, // 992px
          xl: 8, // 1280px
          '2xl': 8, // 1536px
        }}
        pr={{
          base: 2,
          sm: 2, // 480px
          md: 2, // 768px
          lg: 8, // 992px
          xl: 20, // 1280px
          '2xl': 20, // 1536px
        }}
      >
        <VStack
          spacing={{
            base: '20px',
            sm: '20px', // 480px
            md: '20px', // 768px
            lg: '20px', // 992px
            xl: '40px', // 1280px
            '2xl': '60px', // 1536px
          }}
          mt={{
            base: '0',
            sm: '40px', // 480px
            md: '40px', // 768px
            lg: '40px', // 992px
            xl: '40px', // 1280px
            '2xl': '40px', // 1536px
          }}
        >
          <ImageLogo />
          <ImageTextHeader />
        </VStack>
        <VStack spacing={0}>
          <ImageOba />
          <TextAndButton />
        </VStack>
      </HStack>
    );
  }

  return (
    <VStack spacing={0} py={7}>
      <ImageLogo />
      <ImageOba />
      <ImageTextHeader />
      <TextAndButton />
    </VStack>
  );
}
