import { Box, Flex, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';
import CustomNextImage from '@components/ChakraNextImage/newNextImage';
import { WinnerPrize } from '@interfaces/Winners';

export default BannerWinnerPrizes;

type WinnerPrizesModalProps = {
  winners: WinnerPrize[];
  onSelected: (winner: WinnerPrize) => void;
};

function BannerWinnerPrizes({ winners, onSelected }: WinnerPrizesModalProps) {
  const winnerNames = ["Selma A. M. B.", "Mirian P. A.","Cristina B. S."]
  return (
    <Flex
      w="full"
      flexDir="column"
      pb={{
        base: 0,
        sm: 0, // 480px
        md: 10, // 768px
        lg: 10, // 992px
        xl: 10, // 1280px
        '2xl': 10, // 1536px
      }}
    >
      <Text
        alignSelf={{
          base: 'center',
          sm: 'center', // 480px
          md: 'start', // 768px
          lg: 'start', // 992px
          xl: 'start', // 1280px
          '2xl': 'center', // 1536px
        }}
        ml={{
          base: '5vw',
          sm: '5vw', // 480px
          md: '5vw', // 768px
          lg: '5vw', // 992px
          xl: '5vw', // 1280px
          '2xl': '-850px', // 1536px
        }}
        fontSize={{
          base: '25px',
          sm: '30px', // 480px
          md: '30px', // 768px
          lg: '40px', // 992px
          xl: '40px', // 1280px
          '2xl': '40px', // 1536px
        }}
        fontFamily="Bigola-Display-Italic"
        color={'#ffffff'}
      >
        Ganhadores da Promoção
      </Text>
      <Wrap
        spacingX={{
          base: '35px',
          sm: '35px', // 480px
          md: '25px', // 768px
          lg: '35px', // 992px
          xl: '0px', // 1280px
          '2xl': '35px', // 1536px
        }}
        align="center"
        justify={{
          base: 'space-evenly',
          sm: 'space-evenly', // 480px
          md: 'space-evenly', // 768px
          lg: 'space-evenly', // 992px
          xl: 'space-evenly', // 1280px
          '2xl': 'center', // 1536px
        }}
      >
        {winners?.map((winner, index) => (
          <WrapItem p={4} cursor={'pointer'} key={`wrap-item-${index}`}>
            <VStack onClick={() => onSelected(winner)}>
              <Box
                w={{
                  base: '120px',
                  sm: '180px', // 480px
                  md: '180px', // 768px
                  lg: '200px', // 992px
                  xl: '280px', // 1280px
                  '2xl': '280px', // 1536px
                }}
              >
                <CustomNextImage
                  src="/imgs/lojas_rede/car_prize.png"
                  alt="car_prize"
                  width={'331px'}
                  height={'280px'}
                />
              </Box>

              <Text
                fontSize={{
                  base: '20px',
                  sm: '25px', // 480px
                  md: '25px', // 768px
                  lg: '35px', // 992px
                  xl: '35px', // 1280px
                  '2xl': '40px', // 1536px
                }}
                fontFamily="Bigola-Display-Italic"
                color={'#fbee38'}
              >
                {winnerNames[index]}
              </Text>
            </VStack>
          </WrapItem>
        ))}
      </Wrap>
      <VStack
        w={{
          base: '90vw',
          sm: '90vw', // 480px
          md: '90vw', // 768px
          lg: '80vw', // 992px
          xl: '90vw', // 1280px
          '2xl': '60vw', // 1536px
        }}
        height={{
          base: '150px',
          sm: '140px', // 480px
          md: '160px', // 768px
          lg: '190px', // 992px
          xl: '245px', // 1280px
          '2xl': '290px', // 1536px
        }}
        alignSelf={'center'}
        my={{
          base: '0px',
          sm: '20px', // 480px
          md: '20px', // 768px
          lg: '20px', // 992px
          xl: '20px', // 1280px
          '2xl': '20px', // 1536px
        }}
        py={{
          base: 0,
          sm: 2, // 480px
          md: 2, // 768px
          lg: 0, // 992px
          xl: 2, // 1280px
          '2xl': 2, // 1536px
        }}
        px={{
          base: 0,
          sm: 5, // 480px
          md: 5, // 768px
          lg: 5, // 992px
          xl: 0, // 1280px
          '2xl': 0, // 1536px
        }}
        borderRadius={25}
      >
        <Text
          alignSelf={{
            base: 'start',
            sm: 'start', // 480px
            md: 'start', // 768px
            lg: 'start', // 992px
            xl: 'start', // 1280px
            '2xl': 'center', // 1536px
          }}
          ml={{
            base: '0px',
            sm: '0px', // 480px
            md: '0px', // 768px
            lg: '0px', // 992px
            xl: '0px', // 1280px
            '2xl': '-1100px', // 1536px
          }}
          color={'white'}
          fontSize={{
            base: '18px',
            sm: '20px', // 480px
            md: '20px', // 768px
            lg: '20px', // 992px
            xl: '25px', // 1280px
            '2xl': '30px', // 1536px
          }}
          fontFamily="Bigola-Display-Italic"
        >
          Marcas parceiras:
        </Text>

        <CustomNextImage
          src="/imgs/lojas_rede/partines.png"
          alt="partines"
          width={'1049px'}
          height={'180px'}
        />
      </VStack>
    </Flex>
  );
}
