import {
  Box,
  Button,
  Flex,
  HStack,
  Stack,
  Text,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react';
import CustomNextImage from '@components/ChakraNextImage/newNextImage';

export default BannerHowParticipate;

function BannerHowParticipate() {
  const [isLargerThan480] = useMediaQuery('(min-width: 480px)');

  return (
    <Flex
      mt={'-10'}
      w="full"
      flexDir="column"
      mb={{
        base: '-45px',
        sm: 0, // 480px
        md: 0, // 768px
        lg: 0, // 992px
        xl: 0, // 1280px
        '2xl': 0, // 1536px
      }}
    >
      <Box alignSelf={isLargerThan480 ? 'center' : 'end'}>
        <Text
          alignSelf={isLargerThan480 ? 'auto' : 'center'}
          ml={{
            base: '10vw',
            sm: '1vw', // 480px
            md: '1vw', // 768px
            lg: '1vw', // 992px
            xl: '1vw', // 1280px
            '2xl': '1vw', // 1536px
          }}
          mb={{
            base: '0px',
            sm: '-15px', // 480px
            md: '-40px', // 768px
            lg: '-40px', // 992px
            xl: '-40px', // 1280px
            '2xl': '-60px', // 1536px
          }}
          fontSize={{
            base: '25px',
            sm: '30px', // 480px
            md: '30px', // 768px
            lg: '40px', // 992px
            xl: '46px', // 1280px
            '2xl': '40px', // 1536px
          }}
          fontFamily="Bigola-Display-Italic"
          color={'#fbee38'}
        >
          Como faço para participar?
        </Text>

        <Box position="relative" zIndex={2}>
          {isLargerThan480 ? (
            <CustomNextImage
              alt="banner_how_participate"
              src="/imgs/lojas_rede/banner_how_participate.png"
              width={'1350px'}
              height={'400px'}
            />
          ) : (
            <CustomNextImage
              alt="banner_how_participate_mobile"
              src="/imgs/lojas_rede/banner_how_participate_mobile.png"
              width={'375px'}
              height={'375px'}
            />
          )}
        </Box>
        <Stack
          direction={{
            base: 'column',
            sm: 'column', // 480px
            md: 'row', // 768px
            lg: 'row', // 992px
            xl: 'row', // 1280px
            '2xl': 'row', // 1536px
          }}
          bg="#f8f8f8"
          w={{
            base: '310px',
            sm: '70vw', // 480px
            md: '70vw', // 768px
            lg: '70vw', // 992px
            xl: '70vw', // 1280px
            '2xl': '1050px', // 1536px
          }}
          borderRadius={25}
          p={2}
          pb={4}
          top={-14}
          ml={isLargerThan480 ? 7 : 14}
          zIndex={1}
          pt={14}
          position={'relative'}
          align="center"
          justify={{
            base: 'space-around',
            sm: 'space-around', // 480px
            md: 'space-between', // 768px
            lg: 'space-between', // 992px
            xl: 'space-between', // 1280px
            '2xl': 'space-between', // 1536px
          }}
          pr={{
            base: '10px',
            sm: '10px', // 480px
            md: '10px', // 768px
            lg: '50px', // 992px
            xl: '90px', // 1280px
            '2xl': '90px', // 1536px
          }}
        >
          <HStack spacing={0}>
            <CustomNextImage
              alt="logo-clube"
              src="/imgs/lojas_rede/logo-clube.png"
              width={'196px'}
              height={'70px'}
            />
            <VStack
              fontSize={{
                base: '16px',
                sm: '16px', // 480px
                md: '17px', // 768px
                lg: '20px', // 992px
                xl: '30px', // 1280px
                '2xl': '45px', // 1536px
              }}
              fontFamily={{
                base: 'Raleway',
                sm: 'Raleway', // 480px
                md: 'Bigola-Display-Italic', // 768px
                lg: 'Bigola-Display-Italic', // 992px
                xl: 'Bigola-Display-Italic', // 1280px
                '2xl': 'Bigola-Display-Italic', // 1536px
              }}
              fontWeight="700"
              spacing={0}
              lineHeight={1.2}
              align={'center'}
            >
              <Text>Ainda não faz parte</Text>
              <Text>do nosso Clube?</Text>
            </VStack>
          </HStack>
          <a href="https://clube.lojasrede.com.br/" target="_blank">
            <Button
              borderWidth="0px"
              bg="#FF0000"
              _hover={{ bg: 'gray' }}
              _active={{ bg: 'gray' }}
              borderRadius={30}
              variant="outline"
              loadingText="Carregando"
              spinnerPlacement="start"
              size={{
                base: 'md',
                sm: 'md', // 480px
                md: 'md', // 768px
                lg: 'md', // 992px
                xl: 'lg', // 1280px
                '2xl': 'lg', // 1536px
              }}
            >
              <Text
                color="#FFFFFF"
                fontFamily="Montserrat"
                fontSize={{
                  base: '26px',
                  sm: '26px', // 480px
                  md: '20px', // 768px
                  lg: '20px', // 992px
                  xl: '25px', // 1280px
                  '2xl': '25px', // 1536px
                }}
              >
                Cadastrar agora
              </Text>
            </Button>
          </a>
        </Stack>
      </Box>
    </Flex>
  );
}
