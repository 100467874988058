import {
  Container,
  Box,
  VStack,
  Text,
  Link,
  Stack,
  StackDivider,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

export default function Footer() {
  const router = useRouter();
  const { t } = useTranslation('landing-page');
  return (
    <Box  
    // mt={[5, 6, 9, 12]}
      bg={'lpFooterColor'}
      as={motion.div}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition="1s linear"
      viewport={{ once: true }}
    >
      <Container maxW={{ base: 'full', md: '5xl', xl: '1690px' }} >
        <VStack spacing={5}pt={8} pb={5} pl={{
                  base: 'full',
                  sm: 'full', // 480px
                  md: 'full', // 768px
                  lg: '14', // 992px
                  xl: '14', // 1280px
                  '2xl': '14', // 1536px
                }} pr={{
                  base: 'full',
                  sm: 'full', // 480px
                  md: 'full', // 768px
                  lg: '14', // 992px
                  xl: '14', // 1280px
                  '2xl': '14', // 1536px
                }}>
          <Stack
            direction={['column', 'column', 'row']}
            spacing="24px"
            align="center"
          >
            <Link
              variant="lpFooterLinks"
              onClick={() => {
                router.push('/faq');
              }}
            >
              {t('banner-footer-duvidas')}
            </Link>
            <StackDivider
              display={{ base: 'none', md: 'flex' }}
              alignSelf={'center'}
              w="4px"
              h="26px"
              bgColor="#FFFFFF"
            />
            <Link
              variant="lpFooterLinks"
              onClick={() => {
                router.push('/terms');
              }}
            >
              {t('banner-footer-politica')}
            </Link>
          </Stack>
          <Text variant="lpFooterText" >
          {t('banner-footer-description')}
          </Text>
          <Text variant="lpFooterText2">Desenvolvido por Máquina de Promos</Text>
        </VStack>
      </Container>
    </Box>
  );
}
