import { Flex, useMediaQuery } from '@chakra-ui/react';
import CustomNextImage from '@components/ChakraNextImage/newNextImage';
export default BannerCars;

function BannerCars() {
  const [isLargerThan480] = useMediaQuery('(min-width: 480px)');

  return (
    <Flex w="full" justify="center" mt="60px">
      {isLargerThan480 ? (
        <>
          <CustomNextImage
            src={'/imgs/lojas_rede/banner_cars_text.png'}
            alt="banner_cars_text"
            layout="intrinsic"
            width={'1157px'}
            height={'650px'}
          />
        </>
      ) : (
        <CustomNextImage
          src={'/imgs/lojas_rede/banner_cars_mobile.png'}
          alt="banner_cars_mobile"
          layout="intrinsic"
          width={'395px'}
          height={'375px'}
        />
      )}
    </Flex>
  );
}
